import Vue from 'vue'
import Router from 'vue-router'
import project from './../project/index'
const Home = () => import('../views/pc/home.vue')
const HomeEng = () => import('../views/pc/home-eng.vue')
//abouts
const Introduce = () => import('../views/pc/aboutds/introduce.vue')
const IntroduceEng = () => import('../views/pc/aboutds/introduce-eng.vue')
const Value = () => import('../views/pc/aboutds/value.vue')
const ValueEng = () => import('../views/pc/aboutds/value-eng.vue')
const Kigao = () => import('../views/pc/aboutds/kigao.vue')
const KigaoEng = () => import('../views/pc/aboutds/kigao-eng.vue')
const Technology = () => import('../views/pc/aboutds/technology.vue')
const TechnologyEng = () => import('../views/pc/aboutds/technology-eng.vue')
const Honor = () => import('../views/pc/aboutds/honor.vue')
const HonorEng = () => import('../views/pc/aboutds/honor-eng.vue')
const Uniwill = () => import('../views/pc/aboutds/uniwill.vue')
const UniwillEng = () => import('../views/pc/aboutds/uniwill-eng.vue')
const Mission = () => import('../views/pc/aboutds/mission.vue')
const MissionEng = () => import('../views/pc/aboutds/mission-eng.vue')
const DsthEng = () => import('../views/pc/aboutds/dsth-eng.vue')

//industry
const Windenergy = () => import('../views/pc/industry/windenergy.vue')
const WindenergyEng = () => import('../views/pc/industry/windenergy-eng.vue')
const Composite = () => import('../views/pc/industry/composite.vue')
const CompositeEng = () => import('../views/pc/industry/composite-eng.vue')
const Mucus = () => import('../views/pc/industry/mucus.vue')
const MucusEng = () => import('../views/pc/industry/mucus-eng.vue')
const Photovoltaic = () => import('../views/pc/industry/photovoltaic.vue')
const PhotovoltaicEng = () => import('../views/pc/industry/photovoltaic-eng.vue')
const Electric = () => import('../views/pc/industry/electric.vue')
const ElectricEng = () => import('../views/pc/industry/electric-eng.vue')

const Demo = () => import('../views/pc/demo.vue')
const Contactus = () => import('../views/pc/contactus.vue')
const ContactusEng = () => import('../views/pc/contactus-eng.vue')
const Recruitment = () => import('../views/pc/recruitment.vue')
const RecruitmentEng = () => import('../views/pc/recruitment-eng.vue')
const News = () => import('../views/pc/news.vue')
const NewsEng = () => import('../views/pc/news-eng.vue')
const NewsNew = () => import('../views/pc/news_new.vue')
const NewsNewEng = () => import('../views/pc/news_new-eng.vue')
const Media = () => import('../views/pc/media.vue')
const MediaEng = () => import('../views/pc/media-eng.vue')
const Legalnotice = () => import('../views/pc/legalnotice.vue')
const LegalnoticeEng = () => import('../views/pc/legalnotice-eng.vue')

const MobileRecruitment = () => import('./../views/mobile/recruitment.vue')
const MobileRecruitmentEng = () => import('./../views/mobile/recruitment-eng.vue')
const MobilePdf = () => import('./../views/mobile/pdf.vue')
const Control = () => import('../views/pc/control.vue')

const Scheme1M = () => import('./../views/pdf_m/scheme_1.vue')
const Scheme2M = () => import('./../views/pdf_m/scheme_2.vue')
const Scheme3M = () => import('./../views/pdf_m/scheme_3.vue')
const Scheme4M = () => import('./../views/pdf_m/scheme_4.vue')
const Scheme5M = () => import('./../views/pdf_m/scheme_5.vue')
const Scheme6M = () => import('./../views/pdf_m/scheme_6.vue')
const Scheme7M = () => import('./../views/pdf_m/scheme_7.vue')
const Scheme8M = () => import('./../views/pdf_m/scheme_8.vue')
const Scheme9M = () => import('./../views/pdf_m/scheme_9.vue')
const Scheme10M = () => import('./../views/pdf_m/scheme_10.vue')
const Scheme11M = () => import('./../views/pdf_m/scheme_11.vue')
const Scheme12M = () => import('./../views/pdf_m/scheme_12.vue')
const Scheme13M = () => import('./../views/pdf_m/scheme_13.vue')
const Scheme14M = () => import('./../views/pdf_m/scheme_14.vue')
const Scheme15M = () => import('./../views/pdf_m/scheme_15.vue')
const Scheme16M = () => import('./../views/pdf_m/scheme_16.vue')
const Scheme17M = () => import('./../views/pdf_m/scheme_17.vue')
const Scheme18M = () => import('./../views/pdf_m/scheme_18.vue')
const Scheme19M = () => import('./../views/pdf_m/scheme_19.vue')

const Scheme1 = () => import('./../views/pdf/scheme_1.vue')
const Scheme2 = () => import('./../views/pdf/scheme_2.vue')
const Scheme3 = () => import('./../views/pdf/scheme_3.vue')
const Scheme4 = () => import('./../views/pdf/scheme_4.vue')
const Scheme5 = () => import('./../views/pdf/scheme_5.vue')
const Scheme6 = () => import('./../views/pdf/scheme_6.vue')
const Scheme7 = () => import('./../views/pdf/scheme_7.vue')
const Scheme8 = () => import('./../views/pdf/scheme_8.vue')
const Scheme9 = () => import('./../views/pdf/scheme_9.vue')
const Scheme10 = () => import('./../views/pdf/scheme_10.vue')
const Scheme11 = () => import('./../views/pdf/scheme_11.vue')
const Scheme12 = () => import('./../views/pdf/scheme_12.vue')
const Scheme13 = () => import('./../views/pdf/scheme_13.vue')
const Scheme14 = () => import('./../views/pdf/scheme_14.vue')
const Scheme15 = () => import('./../views/pdf/scheme_15.vue')
const Scheme16 = () => import('./../views/pdf/scheme_16.vue')
const Scheme17 = () => import('./../views/pdf/scheme_17.vue')
const Scheme18 = () => import('./../views/pdf/scheme_18.vue')
const Scheme19 = () => import('./../views/pdf/scheme_19.vue')


Vue.use(Router)

export default new Router({
  mode: 'history',
  base: `/${project.mid}/`,
  routes: [
    {
      path: '/home',
      name: 'home',
      component: Home,
      meta: {
        loginAuth: false,
        // name: "首页"
      }
    },
    {
      path: '/home-eng',
      name: 'home-eng',
      component: HomeEng,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/demo',
      name: 'demo',
      component: Demo,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/introduce',
      name: 'introduce',
      component: Introduce,
      meta: {
        loginAuth: false,
        name: "企业介绍"
      }
    },
    {
      path: '/introduce-eng',
      name: 'introduce-eng',
      component: IntroduceEng,
      meta: {
        loginAuth: false,
        name: "About TS"
      }
    },
    {
      path: '/uniwill',
      name: 'uniwill',
      component: Uniwill,
      meta: {
        loginAuth: false,
        name: "志合新材"
      }
    },
    {
      path: '/uniwill-eng',
      name: 'uniwill-eng',
      component: UniwillEng,
      meta: {
        loginAuth: false,
        name: "Uniwill"
      }
    },
    {
      path: '/kigao',
      name: 'kigao',
      component: Kigao,
      meta: {
        loginAuth: false,
        name: "道生季语"
      }
    },
    {
      path: '/kigao-eng',
      name: 'kigao-eng',
      component: KigaoEng,
      meta: {
        loginAuth: false,
        name: "Ji Message"
      }
    },
    {
      path: '/technology',
      name: 'technology',
      component: Technology,
      meta: {
        loginAuth: false,
        name: "道达科技"
      }
    },
    {
      path: '/technology-eng',
      name: 'technology-eng',
      component: TechnologyEng,
      meta: {
        loginAuth: false,
        name: "Technology"
      }
    },
    {
      path: '/honor',
      name: 'honor',
      component: Honor,
      meta: {
        loginAuth: false,
        name: "荣誉及发展历程"
      }
    },
    {
      path: '/honor-eng',
      name: 'honor-eng',
      component: HonorEng,
      meta: {
        loginAuth: false,
        name: "Development"
      }
    },
    {
      path: '/value',
      name: 'value',
      component: Value,
      meta: {
        loginAuth: false,
        name: "企业价值观"
      }
    },
    {
      path: '/value-eng',
      name: 'value-eng',
      component: ValueEng,
      meta: {
        loginAuth: false,
        name: "TS Values"
      }
    },
    {
      path: '/mission',
      name: 'mission',
      component: Mission,
      meta: {
        loginAuth: false,
        name: "愿景使命"
      }
    },
    {
      path: '/mission-eng',
      name: 'mission-eng',
      component: MissionEng,
      meta: {
        loginAuth: false,
        name: "Mission"
      }
    },
    {
      path: '/dsth-eng',
      name: 'dsth-eng',
      component: DsthEng,
      meta: {
        loginAuth: false,
        name: "Mission"
      }
    },
    {
      path: '/windenergy',
      name: 'windenergy',
      component: Windenergy,
      meta: {
        loginAuth: false,
        name: "风能产业"
      }
    },
    {
      path: '/windenergy-eng',
      name: 'windenergy-eng',
      component: WindenergyEng,
      meta: {
        loginAuth: false,
        name: "WIND ENERGY"
      }
    },
    {
      path: '/composite',
      name: 'composite',
      component: Composite,
      meta: {
        loginAuth: false,
        name: "通用复合材料"
      }
    },
    {
      path: '/composite-eng',
      name: 'composite-eng',
      component: CompositeEng,
      meta: {
        loginAuth: false,
        name: "Composite"
      }
    },
    {
      path: '/mucus',
      name: 'mucus',
      component: Mucus,
      meta: {
        loginAuth: false,
        name: "新能源汽车及高端工业胶"
      }
    },
    {
      path: '/mucus-eng',
      name: 'mucus-eng',
      component: MucusEng,
      meta: {
        loginAuth: false,
        name: "Automotive"
      }
    },
    {
      path: '/photovoltaic',
      name: 'photovoltaic',
      component: Photovoltaic,
      meta: {
        loginAuth: false,
        name: "光伏产业"
      }
    },
    {
      path: '/photovoltaic-eng',
      name: 'photovoltaic-eng',
      component: PhotovoltaicEng,
      meta: {
        loginAuth: false,
        name: "PV"
      }
    },
    {
      path: '/electric',
      name: 'electric',
      component: Electric,
      meta: {
        loginAuth: false,
        name: "电气绝缘"
      }
    },
    {
      path: '/electric-eng',
      name: 'electric-eng',
      component: ElectricEng,
      meta: {
        loginAuth: false,
        name: "Electric"
      }
    },
    {
      path: '/contactus',
      name: 'contactus',
      component: Contactus,
      meta: {
        loginAuth: false,
        name: "联系我们"
      }
    },
    {
      path: '/contactus-eng',
      name: 'contactus-eng',
      component: ContactusEng,
      meta: {
        loginAuth: false,
        name: "Contact TS"
      }
    },
    {
      path: '/recruitment',
      name: 'recruitment',
      component: Recruitment,
      meta: {
        loginAuth: false,
        name: "加入道生"
      }
    },
    {
      path: '/recruitment-eng',
      name: 'recruitment-eng',
      component: RecruitmentEng,
      meta: {
        loginAuth: false,
        name: "加入道生"
      }
    },
    {
      path: '/news',
      name: 'news',
      component: News,
      meta: {
        loginAuth: false,
        name: "新闻动态"
      }
    },
    {
      path: '/news-eng',
      name: 'news-eng',
      component: NewsEng,
      meta: {
        loginAuth: false,
        name: "News"
      }
    },
    {
      path: '/news_new',
      name: 'news_new',
      component: NewsNew,
      meta: {
        loginAuth: false,
        name: "新闻动态"
      }
    },
    {
      path: '/news_new-eng',
      name: 'news_new-eng',
      component: NewsNewEng,
      meta: {
        loginAuth: false,
        name: "News"
      }
    },
    {
      path: '/media',
      name: 'media',
      component: Media,
      meta: {
        loginAuth: false,
        name: "多媒体"
      }
    },
    {
      path: '/media-eng',
      name: 'media-eng',
      component: MediaEng,
      meta: {
        loginAuth: false,
        name: "多媒体"
      }
    },
    {
      path: '/legalnotice',
      name: 'legalnotice',
      component: Legalnotice,
      meta: {
        loginAuth: false,
        name: "法律声明"
      }
    },
    {
      path: '/legalnotice-eng',
      name: 'legalnotice-eng',
      component: LegalnoticeEng,
      meta: {
        loginAuth: false,
        name: "TERMS"
      }
    },
    {
      path: '/mobile_recruitment',
      name: 'mobile_recruitment',
      component: MobileRecruitment,
      meta: {
        loginAuth: false,
        name: "加入道生"
      }
    },
    {
      path: '/mobile_recruitment-eng',
      name: 'mobile_recruitment-eng',
      component: MobileRecruitmentEng,
      meta: {
        loginAuth: false,
        name: "Join TS"
      }
    },
    {
      path: '/mobile_pdf',
      name: 'mobile_pdf',
      component: MobilePdf,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/control',
      name: 'control',
      component: Control,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/scheme_1',
      name: 'scheme_1',
      component: Scheme1,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/scheme_2',
      name: 'scheme_2',
      component: Scheme2,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/scheme_3',
      name: 'scheme_3',
      component: Scheme3,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/scheme_4',
      name: 'scheme_4',
      component: Scheme4,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/scheme_5',
      name: 'scheme_5',
      component: Scheme5,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/scheme_6',
      name: 'scheme_6',
      component: Scheme6,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/scheme_7',
      name: 'scheme_7',
      component: Scheme7,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/scheme_8',
      name: 'scheme_8',
      component: Scheme8,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/scheme_9',
      name: 'scheme_9',
      component: Scheme9,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/scheme_10',
      name: 'scheme_10',
      component: Scheme10,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/scheme_11',
      name: 'scheme_11',
      component: Scheme11,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/scheme_12',
      name: 'scheme_12',
      component: Scheme12,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/scheme_13',
      name: 'scheme_13',
      component: Scheme13,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/scheme_14',
      name: 'scheme_14',
      component: Scheme14,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/scheme_15',
      name: 'scheme_15',
      component: Scheme15,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/scheme_16',
      name: 'scheme_16',
      component: Scheme16,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/scheme_17',
      name: 'scheme_17',
      component: Scheme17,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/scheme_18',
      name: 'scheme_18',
      component: Scheme18,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/scheme_19',
      name: 'scheme_19',
      component: Scheme19,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/scheme_1_m',
      name: 'scheme_1_m',
      component: Scheme1M,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/scheme_2_m',
      name: 'scheme_2_m',
      component: Scheme2M,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/scheme_3_m',
      name: 'scheme_3_m',
      component: Scheme3M,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/scheme_4_m',
      name: 'scheme_4_m',
      component: Scheme4M,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/scheme_5_m',
      name: 'scheme_5_m',
      component: Scheme5M,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/scheme_6_m',
      name: 'scheme_6_m',
      component: Scheme6M,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/scheme_7_m',
      name: 'scheme_7_m',
      component: Scheme7M,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/scheme_8_m',
      name: 'scheme_8_m',
      component: Scheme8M,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/scheme_9_m',
      name: 'scheme_9_m',
      component: Scheme9M,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/scheme_10_m',
      name: 'scheme_10_m',
      component: Scheme10M,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/scheme_11_m',
      name: 'scheme_11_m',
      component: Scheme11M,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/scheme_12_m',
      name: 'scheme_12_m',
      component: Scheme12M,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/scheme_13_m',
      name: 'scheme_13_m',
      component: Scheme13M,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/scheme_14_m',
      name: 'scheme_14_m',
      component: Scheme14M,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/scheme_15_m',
      name: 'scheme_15_m',
      component: Scheme15M,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/scheme_16_m',
      name: 'scheme_16_m',
      component: Scheme16M,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/scheme_17_m',
      name: 'scheme_17_m',
      component: Scheme17M,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/scheme_18_m',
      name: 'scheme_18_m',
      component: Scheme18M,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/scheme_19_m',
      name: 'scheme_19_m',
      component: Scheme19M,
      meta: {
        loginAuth: false,
        name: "Pdf"
      }
    },
    {
      path: '/',
      redirect: '/home'
    },
    {
      path: '*',
      redirect: '/home'
    },
  ]
})
